import Loading from 'components/loading/Loading';
import ResponseItem from 'components/register/ResponseItem';
import useFetchDepartments from 'hooks/useFetchDepartments';
import React from 'react';

type InfoProps = {
  userData: any;
};
const Info: React.FC<InfoProps> = ({ userData }) => {
  const { loading: loadingDepartments, data: dbDepartments } = useFetchDepartments();

  if (loadingDepartments) {
    return (
      <div className="p-20">
        <Loading containerClassName="my-3" />
      </div>
    );
  }
  const {
    phone,
    email,
    faculty,
    aboutYou,
    appliedTo,
  } = userData;

  return (
    <ul className="mt-2 py-0.5 px-6 border border-transparent rounded-xl text-base bg-gray-100">
      <br />
      <ResponseItem title="Numar de telefon" response={phone} />
      <ResponseItem title="Email" response={email} />
      <ResponseItem title="Facultate" response={faculty} />
      <br />

      <p className="text-lg font-bold text-gray-600">Despre tine</p>

      {aboutYou.map(({ question, answer }: any) => (
        <ResponseItem key={question} title={question} response={answer} />
      ))}

      <br />

      <p className="text-lg font-bold text-gray-600">Departamentele in care te-ai inscris</p>
      <br />
      {appliedTo.map(({ accepted, name, questions }: any) => {
        const department = (dbDepartments as any)[name] as string;
        return (
          <div>
            <p className="text-lg font-bold text-sky-600">
              {department}
              {'     '}
              {accepted ? (
                <span className="text-green-600">Acceptat</span>
              ) : (
                <span className="text-red-600">Neacceptat</span>
              )}
            </p>
            <br />
            {questions?.map(({ question, answer }: any) => (
              <ResponseItem key={question} title={question} response={answer} />
            ))}
            <br />
          </div>
        );
      })}
    </ul>
  );
};

export default Info;
