import ERROR_MESSAGES from 'helpers/error-messages';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import getDepartments from 'services/api/departments';

export type DepartmentsType = {
  [key: string]: string;
}

const useFetchDepartments = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DepartmentsType>({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: response } = await getDepartments();
        setData(response);
      } catch (error: any) {
        toast.error(error?.message || ERROR_MESSAGES.default);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  return { loading, data };
};

export default useFetchDepartments;
