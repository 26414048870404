import React from 'react';
import UserDataForm from 'components/userDataForm';
import FormContainer from 'components/formContainer';

const HomePage: React.FC = () => (
  <div className="w-screen h-screen">
    <FormContainer title="Inscrieri">
      <div className="overflow-y-scroll">
        <UserDataForm />
      </div>
    </FormContainer>
  </div>
);

export default HomePage;
