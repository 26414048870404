import React, { useState, useEffect } from 'react';
import LoginAdmin from 'pages/LoginAdmin';
import LoggedInStats from 'pages/LoggedInStats';
import { useAuth } from 'react-oidc-context';
import { toast } from 'react-toastify';

const LoginController: React.FC = () => {
  const auth = useAuth();
  const [authError, setAuthError] = useState(null);
  const [authUser, setAuthUser] = useState<any>(null);

  useEffect(() => {
    const basedOnAuth = async () => {
      if (auth.error) {
        toast.error(`You are logged out. ${auth.error}`);
        await auth.signoutRedirect();
      }
    };

    if (authError !== null) {
      toast.error(`Authentication error. ${authError}`);
      setAuthError(null);
    } else {
      basedOnAuth();
    }
  }, [authError, auth.error]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      setAuthUser(auth.user);
    }
  }, [auth.user]);

  // This is a nice feature for auto login, but it's not needed for now
  // useEffect(() => {
  //   if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
  //     auth.signinRedirect();
  //   }
  // }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

  // if (auth.activeNavigator) {
  //   // eslint-disable-next-line react/jsx-boolean-value
  //   return <LoginAdmin loading={true} login={() => {}} />;
  // }

  const login = async () => {
    await auth.signinPopup().then((user) => {
      setAuthUser(user);
    }).catch((error) => {
      setAuthError(error);
    });
  };

  const logout = async () => {
    await auth.signoutRedirect();
  };

  if (auth.isAuthenticated) {
    return <LoggedInStats user={authUser} logout={logout} />;
  }

  return <LoginAdmin loading={auth.isLoading} login={login} />;
};

export default LoginController;
