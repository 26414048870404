import React from 'react';
import cx from 'classnames';
import { useFormikContext, getIn } from 'formik';

import styles from './Form.module.css';

export type OptionType = { value: string | number; name: string };

type HTMLSelectProps = JSX.IntrinsicElements['select'];

type SelectProps = HTMLSelectProps & {
  options: OptionType[];
};

type SelectFieldProps = SelectProps & {
  name: string;
  placeholder?: string;
  label?: string;
  className?: string;
  selectClassName?: string;
  error?: any;
};

export const Select: React.FC<SelectProps> = ({
  name,
  placeholder,
  className,
  options,
  ...rest
}) => (
  <select className={cx(styles.input, className)} name={name} {...rest}>
    <option value="">{placeholder}</option>
    {options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.name}
      </option>
    ))}
  </select>
);

export const SelectField: React.FC<SelectFieldProps> = ({
  label,
  name,
  className,
  selectClassName,
  options,
  placeholder,
  error,
  ...rest
}) => (
  <div className={className}>
    {label && <label className={styles.label}>{label}</label>}

    <select className={cx(styles.input, selectClassName)} name={name} {...rest}>
      <option value="">{placeholder}</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>

    {error && <p className={styles.error}>{error}</p>}
  </div>
);
