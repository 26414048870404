import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import * as yup from 'yup';
import NavigationButtons from 'components/formContainer/NavigationButtons';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from 'components/form';
import { Button } from 'components/buttons';
import useStore from 'store';
import useFetchQuestions from 'hooks/useFetchQuestions';
import DepartmentsSelect from 'components/form/DepartmentsSelect';
import { AnswersType } from 'types/departments';
import { TextAreaFieldHook } from 'components/form/TextArea';

import styles from './DepartmentForm.module.css';

const isRequired = 'Camp obligatoriu';

const DepartmentForm: React.FC = () => {
  const navigate = useNavigate();

  const answers = useStore((state) => state.answers);
  const setAnswers = useStore((state) => state.setAnswers);
  const setAnswer = useStore((state) => state.setAnswer);

  const chooseSecondDepartment = useStore((state) => state.chooseSecondDepartment);
  const setChooseSecondDepartment = useStore((state) => state.setChooseSecondDepartment);

  const [firstDepartment] = useStore((state) => state.departments);
  const setDepartments = useStore((state) => state.setDepartments);

  const stepper = useStore((state) => state.stepper);
  const setStepper = useStore((state) => state.setStepper);

  const registerForm = useStore((state) => state.registerForm);

  const [selectedDepartment, setSelectedDepartment] = useState<string>(firstDepartment || '');
  const [objValidationSchema, setObjValidationSchema] = useState<any>({});

  const { loading, questions } = useFetchQuestions(selectedDepartment);

  const {
    setFieldValue,
    values,
    errors,
    setFieldTouched,
    touched,
    submitCount,
    setValues,
    handleSubmit,
  } = useFormik({
    initialValues: {
      department: firstDepartment,
      chooseSecondDepartment,
    },
    validationSchema: yup.object().shape(objValidationSchema),
    onSubmit: async ({ chooseSecondDepartment: choose, department, ...updatedAnswers }) => {
      await setAnswers(updatedAnswers as AnswersType);

      if (!chooseSecondDepartment) {
        const isRegistered = await registerForm();

        if (isRegistered) {
          navigate('/register-complete');
        }
      }
    },
  });

  useEffect(() => {
    const valuesObjValidation: any = {
      department: yup.string().required(isRequired),
      chooseSecondDepartment: yup.bool(),
    };

    const updatedValues: { [key: string]: string | boolean } = { ...answers, department: selectedDepartment, chooseSecondDepartment };

    questions.forEach(({ id }) => {
      updatedValues[id] = answers[id];

      valuesObjValidation[id] = yup
        .string()
        .when('department', {
          is: selectedDepartment,
          then: yup.string().required(isRequired),
        });
    });

    setObjValidationSchema(valuesObjValidation);
    setValues(updatedValues as any);
  }, [
    questions,
    selectedDepartment,
    setObjValidationSchema,
    setValues,
  ]);

  const { chooseSecondDepartment: choose, department, ...formAnswers } = values;

  return (
    <div className={styles.container}>
      <form className={styles.center} onSubmit={handleSubmit}>
        <div className={cx(styles.center, styles.inputContainer)}>
          <DepartmentsSelect
            name="department"
            placeholder="Selectati departamentul dorit"
            className="w-full"
            onChange={(e) => {
              const dep = e.target.value;
              setSelectedDepartment(dep);
              setDepartments(dep, 0);
            }}
            value={values.department}
            error={errors.department}
          />
          {!loading && questions.map((question) => {
            const name = String(question.id);
            return (
              <TextAreaFieldHook
                className={styles.input}
                label={question.name}
                name={name}
                key={name}
                error={(errors as any)[name]}
                touched={(touched as any)[name]}
                value={(values as any)[name]}
                onChange={(e) => {
                  const { value } = e.target;
                  setFieldValue(name, value, true);
                  setAnswer(name, value);
                }}
                onFocus={() => setFieldTouched(name)}
                submitCount={submitCount}
              />
            );
          })}
          <Checkbox
            className="my-2"
            name="chooseSecondDepartment"
            title="Aplic si pentru alt departament"
            defaultChecked={values.chooseSecondDepartment}
            onCheck={() => {
              const { chooseSecondDepartment: chooseForm } = values;
              setFieldValue('chooseSecondDepartment', !chooseForm);
              setChooseSecondDepartment(!chooseForm);
            }}
          />
        </div>
        <NavigationButtons
          // TODO: get rid of any
          isNextButton={values.chooseSecondDepartment as any}
          isPrevButton
          onClickNext={() => {
            if (Object.keys(errors).length) {
              return;
            }
            setAnswers(formAnswers as AnswersType);
            setStepper(stepper + 1);
            navigate('/second-department');
          }}
          onClickPrev={() => {
            setAnswers(formAnswers as AnswersType);
            setStepper(stepper - 1);
            navigate('/history');
          }}
        />
        {!values.chooseSecondDepartment && (
          <Button type="submit" theme="secondary" className="mt-4" disabled={!!Object.keys(errors).length}>
            Submit
          </Button>
        )}
      </form>
    </div>
  );
};
export default DepartmentForm;
