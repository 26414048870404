import { UserDataType } from 'types/user-data';

const USER_DATA_INITIAL_VALUES: UserDataType = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  faculty: '',
};

export default USER_DATA_INITIAL_VALUES;
