import React from 'react';
import cx from 'classnames';

import styles from './Form.module.css';

type CheckboxProps = {
  name: string;
  title: string;
  checked?: boolean;
  defaultChecked?: boolean;
  status?: 'success' | 'danger';
  error?: string;
  className?: string;
  onCheck: () => void;
};

// eslint-disable-next-line import/prefer-default-export
export const Checkbox: React.FC<CheckboxProps> = ({
  name, title, status, error, className, checked, defaultChecked, onCheck,
}) => (
  <div className={className}>
    <label className={cx(styles.label, 'items-center')}>
      <input
        className={cx(styles.checkbox, {
          [styles[`input-${status}`]]: status,
        })}
        type="checkbox"
        name={name}
        checked={checked}
        defaultChecked={defaultChecked}
        onClick={onCheck}
      />

      {title}
    </label>

    {error && <p className={styles.error}>{error}</p>}
  </div>
);
