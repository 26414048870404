import React from 'react';
import FormContainer from 'components/formContainer';
import Loading from 'components/loading/Loading';
import useStore from 'store';
import useFetchDepartments from 'hooks/useFetchDepartments';
import useFetchPersonalQuestions from 'hooks/useFetchPersonalQuestions';
import ResponseItem from 'components/register/ResponseItem';
import DepartamentResponse from 'components/register/DepartmentResponse';

const RegisterCompletePage: React.FC = () => {
  const payload = useStore((state) => state.payload);

  const { loading: loadingPersonalQuestions, questions } = useFetchPersonalQuestions();
  const { loading: loadingDepartments, data: dbDepartments } = useFetchDepartments();

  if (loadingPersonalQuestions || loadingDepartments) {
    return (
      <div className="w-screen h-screen">
        <FormContainer title="Inscrieri">
          <Loading containerClassName="my-3" />
        </FormContainer>
      </div>
    );
  }

  if (!payload) {
    return <div className="w-screen h-screen">something went wrong please try again</div>;
  }

  const {
    firstName,
    lastName,
    phone,
    email,
    faculty,
    aboutYou,
    appliedTo,
  } = payload;

  return (
    <div className="w-screen h-screen py-12">
      <FormContainer title="Inscrieri">
        <div className="overflow-y-scroll">
          <div className="text-lg text-slate-600 text-center my-2">
            <br />
            <p className="text-lg font-bold text-gray-600">
              Felicitari! V-ati inscris cu succes in ASII
            </p>
            <br />
            <ul>
              <p className="text-lg font-bold text-gray-600">Date personale</p>

              <br />
              <ResponseItem title="Prenume" response={firstName} />
              <ResponseItem title="Nume" response={lastName} />
              <ResponseItem title="Numar de telefon" response={phone} />
              <ResponseItem title="Email" response={email} />
              <ResponseItem title="Facultate" response={faculty} />
              <br />

              <p className="text-lg font-bold text-gray-600">Despre tine</p>

              {aboutYou.map(({ id, answer }) => {
                const question = questions.find((q) => String(q.id) === id);
                return <ResponseItem key={id} title={question?.name || ''} response={answer} />;
              })}

              <br />

              <p className="text-lg font-bold text-gray-600">Departamentele in care te-ai inscris</p>
              <br />
              {appliedTo.map(({ name, questions: q }) => {
                const department = (dbDepartments as any)[name] as string;
                return <DepartamentResponse department={department} departmentAbr={name} responses={q} />;
              })}
            </ul>
          </div>
        </div>
      </FormContainer>
    </div>
  );
};

export default RegisterCompletePage;
