import cx from 'classnames';
import { Button } from 'components/buttons';
import React, { useMemo } from 'react';

type DepartmentFiltersProps = {
  stats: any;
  selectedDepartment: string | null;
  setSelectedDepartment: any;
};

const DepartmentFilters: React.FC<DepartmentFiltersProps> = ({
  stats,
  selectedDepartment,
  setSelectedDepartment,
}) => {
  const statsKeysArray = useMemo(() => Object.keys(stats), [stats]);

  const onSelectDepartment = (stat: string) => {
    if (selectedDepartment === stat) {
      setSelectedDepartment(null);
    } else {
      setSelectedDepartment(stat);
    }
  };

  return (
    <div className="flex w-full my-4">
      {statsKeysArray.map((stat: string, idx: number) => (
        <Button
          key={stat}
          theme={selectedDepartment === stat ? 'secondary' : 'default'}
          className={cx('flex-1 text-center', {
            'mr-4': idx !== statsKeysArray.length - 1,
          })}
          onClick={() => onSelectDepartment(stat)}
        >
          {stat}
          :
          {' '}
          <b>{stats[stat]}</b>
        </Button>
      ))}
    </div>
  );
};

export default DepartmentFilters;
