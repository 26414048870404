import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import cx from 'classnames';
import * as yup from 'yup';
import FakeTextAreas from 'components/fakeTextArea';
import { TextAreaField } from 'components/form/TextArea';
import NavigationButtons from 'components/formContainer/NavigationButtons';
import { useNavigate } from 'react-router-dom';
import mapValues from 'lodash/mapValues';
import { UserHistoryType } from 'types/user-history';
import useStore from 'store';
import useFetchPersonalQuestions from 'hooks/useFetchPersonalQuestions';
import { QuestionType } from 'types/questions';

import styles from './UserHistoryForm.module.css';

const isRequired = 'Camp obligatoriu';
const INITIAL_VALUES: UserHistoryType = {};

const UserHistoryForm = () => {
  const navigate = useNavigate();
  const setUserHistory = useStore((state) => state.setUserHistory);
  const userHistory = useStore((state) => state.userHistory);

  const stepper = useStore((state) => state.stepper);
  const setStepper = useStore((state) => state.setStepper);

  const { loading, questions } = useFetchPersonalQuestions();

  useEffect(() => {
    questions.forEach(({ id }) => {
      INITIAL_VALUES[id] = '';
    });
  }, [questions]);

  const validationSchema = yup.object().shape(mapValues(INITIAL_VALUES, () => yup.string().required(isRequired)));

  return (
    <div className={styles.container}>
      <Formik
        initialValues={userHistory || INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setUserHistory(values);
          setStepper(stepper + 1);
          navigate('/department');
        }}
      >
        <Form className={styles.center}>
          <div className={cx(styles.center, styles.inputContainer)}>
            {loading ? (
              <FakeTextAreas />
            ) : (
              questions.map((question: QuestionType) => (
                <TextAreaField
                  className={styles.input}
                  label={question.name}
                  name={String(question.id)}
                  key={question.id}
                />
              ))
            )}
          </div>
          <NavigationButtons
            isNextButton
            isPrevButton
            onClickPrev={() => {
              setStepper(stepper - 1);
              navigate('/');
            }}
          />
        </Form>
      </Formik>
    </div>
  );
};
export default UserHistoryForm;
