import FormContainer from 'components/formContainer';
import Loading from 'components/loading/Loading';
import useFetchDepartments from 'hooks/useFetchDepartments';
import React from 'react';

import { SelectField } from './Select';

type HTMLSelectProps = JSX.IntrinsicElements['select'];

type DepartmentsSelectProps = {
  name: string;
  placeholder: string;
  error?: any;
} & HTMLSelectProps;

const DepartmentsSelect: React.FC<DepartmentsSelectProps> = (props) => {
  const { loading, data } = useFetchDepartments();

  if (loading) {
    return (
      <div className="w-screen h-screen">
        <FormContainer title="Inscrieri">
          <Loading containerClassName="my-3" />
        </FormContainer>
      </div>
    );
  }

  const options = Object.keys(data).map((key) => ({ value: key, name: data[key] }));

  return (
    <SelectField options={options} {...props} />
  );
};
export default DepartmentsSelect;
