import ERROR_MESSAGES from 'helpers/error-messages';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import getQuestions from 'services/api/questions';
import { QuestionType } from 'types/questions';

const useFetchPersonalQuestions = () => {
  const [questions, setQuestion] = useState<Array<QuestionType>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const { data: response } = await getQuestions('PERSONAL');
        setQuestion(response);
        setLoading(false);
      } catch (error: any) {
        toast.error(error?.message || ERROR_MESSAGES.default);
      }
    };

    fetchQuestions();
  }, []);

  return { loading, questions };
};

export default useFetchPersonalQuestions;
