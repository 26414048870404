import { Button } from 'components/buttons';
import React, { useState } from 'react';
import { PayloadType } from 'types/register-form';

import Info from './Info';

type PersonCardProps = {
  userData: PayloadType;
};

const PersonCard: React.FC<PersonCardProps> = ({ userData }) => {
  const [showInfo, setShowInfo] = useState(false);

  const { firstName, lastName } = userData;

  return (
    <div className="py-2">
      <Button className="w-full" theme="primary" onClick={() => setShowInfo((prev) => !prev)}>
        {lastName}
        {' '}
        {firstName}
      </Button>
      <br />
      {showInfo && <Info userData={userData} />}
    </div>
  );
};

export default PersonCard;
