import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider } from 'react-oidc-context';

import App from './App';
import './index.css';

const replaceState = (_user: any | void): void => {
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname,
  );
  // eslint-disable-next-line no-console
  console.log(_user);
};

const oidConfig = {
  authority: 'https://admin-auth-kc.asii.ro/realms/asii/',
  client_id: 'inscrieri-admin',
  redirect_uri: 'https://inscrieri.asii.ro/admin',
  metadataUrl: 'https://admin-auth-kc.asii.ro/realms/asii/.well-known/openid-configuration',
  onSignInCallback: replaceState,
  loadUserInfo: true,
};

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider {...oidConfig}>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
