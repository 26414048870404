import FormContainer from 'components/formContainer';
import Loading from 'components/loading/Loading';
import useFetchQuestions from 'hooks/useFetchQuestions';
import React from 'react';
import { ResponseType } from 'types/register-form';

import ResponseItem from './ResponseItem';

type DepartamentResponseType = {
  department: string;
  departmentAbr: string;
  responses: ResponseType[];
};

const DepartamentResponse: React.FC<DepartamentResponseType> = ({
  department,
  departmentAbr,
  responses,
}) => {
  const { loading, questions } = useFetchQuestions(departmentAbr);

  if (loading) {
    return (
      <div className="w-screen h-screen">
        <FormContainer title="Inscrieri">
          <Loading containerClassName="my-3" />
        </FormContainer>
      </div>
    );
  }

  return (
    <div>
      <p className="text-lg font-bold text-sky-600">{department}</p>
      <br />
      {questions.map(({ id, name }) => {
        const { answer } = responses.find((response) => response.id === id) || {};
        return <ResponseItem key={id} title={name} response={answer || ''} />;
      })}
      <br />
    </div>
  );
};

export default DepartamentResponse;
