import { FakeTextArea } from 'components/form/TextArea';
import React from 'react';

const FakeTextAreas: React.FC = () => (
  <div>
    <FakeTextArea label="Loading ..." />
    <FakeTextArea label="Loading ..." />
    <FakeTextArea label="Loading ..." />
    <FakeTextArea label="Loading ..." />
    <FakeTextArea label="Loading ..." />
  </div>
);

export default FakeTextAreas;
