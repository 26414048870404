import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {
  AdminPage,
  HomePage,
  HistoryPage,
  DepartmentPage,
  SecondDepartmentPage,
  RegisterCompletePage,
  NotFoundPage,
} from 'pages';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/history" element={<HistoryPage />} />
        <Route path="/department" element={<DepartmentPage />} />
        <Route path="/second-department" element={<SecondDepartmentPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/register-complete" element={<RegisterCompletePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
