import React from 'react';
import FormContainer from 'components/formContainer';
import Loading from 'components/loading/Loading';
import { Button } from 'components/buttons';

const LoginAdmin = ({ loading, login }: { loading: any, login: any }) => (
  <div className="w-screen h-screen">
    <FormContainer title="Administrative page">
      <div className="pt-8">
        {
          loading ? <Loading /> : (
            <Button theme="secondary" onClick={login}>
              Login
            </Button>
          )
        }
      </div>
    </FormContainer>
  </div>
);

export default LoginAdmin;
