import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FormContainer from 'components/formContainer';
import DepartmentForm from 'components/departmentForm';
import Loading from 'components/loading/Loading';
import useStore from 'store';

const DepartmentPage: React.FC = () => {
  const navigate = useNavigate();
  const stepper = useStore((state) => state.stepper);

  useEffect(() => {
    if (stepper === 0) {
      navigate('/');
    } else if (stepper === 1) {
      navigate('/history');
    }
  }, [navigate]);

  return (
    <div className="w-screen h-screen">
      <FormContainer title="Inscrieri">
        <div className="overflow-y-scroll">
          {stepper === 2 ? <DepartmentForm /> : <Loading containerClassName="my-3" />}
        </div>
      </FormContainer>
    </div>
  );
};

export default DepartmentPage;
