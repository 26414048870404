import React from 'react';
import FormContainer from 'components/formContainer';

const NotFoundPage: React.FC = () => (
  <div className="w-screen h-screen">
    <FormContainer title="Not found">
      <div className="text-lg text-slate-600 text-center my-2">
        Hmm, cred ca pagina pe care o cauti nu exista...
      </div>
    </FormContainer>
  </div>
);

export default NotFoundPage;
