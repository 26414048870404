import { Form, Formik } from 'formik';
import cx from 'classnames';
import React from 'react';
import * as yup from 'yup';
import { InputField } from 'components/form';
import NavigationButtons from 'components/formContainer/NavigationButtons';
import { useNavigate } from 'react-router-dom';
import USER_DATA_INITIAL_VALUES from 'store/user-data';
import { UserDataType } from 'types/user-data';
import useStore from 'store';

import styles from './UserDataForm.module.css';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const requiredField = 'Câmp obligatoriu.';

const initialSchema = yup.object().shape({
  firstName: yup.string().required(requiredField),
  lastName: yup.string().required(requiredField),
  email: yup.string().email('Email invalid.').required(requiredField),
  phone: yup.string().matches(phoneRegExp, 'Număr de telefon invalid.').required(requiredField),
  faculty: yup.string().required(requiredField),
});

const UserDataForm: React.FC = () => {
  const navigate = useNavigate();
  const setUserData = useStore((state) => state.setUserData);
  const userData = useStore((state) => state.userData);
  const stepper = useStore((state) => state.stepper);
  const setStepper = useStore((state) => state.setStepper);

  return (
    <div className={styles.container}>
      <Formik<UserDataType>
        initialValues={userData || USER_DATA_INITIAL_VALUES}
        onSubmit={(values) => {
          setUserData(values);
          setStepper(stepper + 1);
          navigate('/history');
        }}
        validationSchema={initialSchema}
      >
        <Form className={styles.center}>
          <div className={cx(styles.center, styles.inputContainer)}>
            <InputField
              name="lastName"
              placeholder="Nume"
              className={styles.input}
            />
            <InputField
              name="firstName"
              placeholder="Prenume"
              className={styles.input}
            />
            <InputField
              name="phone"
              placeholder="Telefon"
              className={styles.input}
            />
            <InputField
              name="email"
              placeholder="Email"
              className={styles.input}
            />
            <InputField
              name="faculty"
              placeholder="Facultate"
              className={styles.input}
            />
          </div>
          <NavigationButtons isNextButton />
        </Form>
      </Formik>
    </div>
  );
};

export default UserDataForm;
