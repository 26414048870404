import { toast } from 'react-toastify';
import create from 'zustand';
import submitForm from 'services/api/submitForm';
import USER_DATA_INITIAL_VALUES from 'store/user-data';
import { AnswersType } from 'types/departments';
import { DepartmentResponseType, PayloadType, ResponseType } from 'types/register-form';
import { Store } from 'types/store';
import { UserDataType } from 'types/user-data';
import { UserHistoryType } from 'types/user-history';
import ERROR_MESSAGES from 'helpers/error-messages';
import getQuestions from 'services/api/questions';

const useStore = create < Store >((set, get) => ({
  userData: USER_DATA_INITIAL_VALUES,
  userHistory: {},
  answers: {},
  stepper: 0,
  response: '',
  departments: [],
  chooseSecondDepartment: false,
  payload: null,

  setPayload: (payload: PayloadType) => set({ payload }),
  setChooseSecondDepartment: (chooseSecondDepartment: boolean) => set({ chooseSecondDepartment }),
  setResponse: (response: string) => set({ response }),
  setUserData: (userData: UserDataType) => set({ userData }),
  setUserHistory: (userHistory: UserHistoryType) => set({ userHistory }),
  setAnswers: (answers: AnswersType) => {
    const oldAnswers = { ...get().answers };
    set({ answers: { ...oldAnswers, ...answers } });
  },
  setAnswer: (answerId: string, value: string) => {
    const oldAnswers = { ...get().answers };

    oldAnswers[answerId] = value;

    set({ answers: oldAnswers });
  },
  setDepartments: (department: string, number: number) => {
    const { departments } = get();

    departments[number] = department;

    set({ departments });
  },
  setStepper: (stepper: number) => set({ stepper }),

  registerForm: async () => {
    const {
      answers,
      userData,
      departments,
      chooseSecondDepartment,
      userHistory,
    } = get();

    const aboutYou: ResponseType[] = Object.entries(userHistory).map(([key, value]) => ({
      id: key,
      answer: value,
    }));

    const chosenDepartments = chooseSecondDepartment ? [...departments] : [departments[0]];

    const promises = chosenDepartments.map(async (department) => {
      const singularPayload: DepartmentResponseType = {
        name: department,
        questions: [],
      };

      try {
        const { data: questions } = await getQuestions(department);

        questions?.forEach((question) => {
          const { id } = question || {};
          if (id) {
            singularPayload.questions.push({
              id,
              answer: answers[id],
            });
          }
        });
      } catch (error: any) {
        toast.error(error?.message || ERROR_MESSAGES.default);
      }

      return singularPayload;
    });

    const appliedTo: DepartmentResponseType[] = await Promise.all(promises);

    const payload: PayloadType = {
      ...userData,
      aboutYou,
      appliedTo,
    };

    try {
      const { data } = await submitForm(payload);
      set({ response: data?.message });
      set({ payload });

      return true;
    } catch (error: any) {
      const response = error.message || ERROR_MESSAGES.default;
      toast.error(response);

      return false;
    }
  },
}));

export default useStore;
