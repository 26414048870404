import DepartmentFilters from 'components/departmentFilters';
import PersonCard from 'components/personCard';
import React, { useEffect, useMemo, useState } from 'react';
import { getDump } from 'services/api/login-admin';
import { PayloadType } from 'types/register-form';
import { Button } from 'components/buttons';
import { toast } from 'react-toastify';

const LoggedInStats = ({ user, logout }: { user: any, logout: any }) => {
  const [dump, setDump] = useState<any>(null);
  const [selectedDepartment, setSelectedDepartment] = useState<string | null>(null);
  useEffect(() => {
    if (user === null || user.access_token === null) {
      return;
    }

    getDump(user?.access_token).then((resp) => {
      setDump(resp?.data);
    }).catch((e) => {
      toast.error(e?.response?.data?.message || 'Something went wrong');
      // eslint-disable-next-line no-console
      if (e?.response?.status) {
        logout();
      }
    });
  }, [user]);

  const { stats, users } = dump || {};
  const parsedUsers = users && JSON.parse(users);

  const filteredUsers = useMemo(() => {
    if (selectedDepartment === null || !parsedUsers?.length) {
      return parsedUsers;
    }

    return parsedUsers?.filter(({ appliedTo }: any) => (
      appliedTo.find(({ name }: any) => name === selectedDepartment)
    ));
  }, [parsedUsers, selectedDepartment]);

  return (
    <div className="flex justify-center items-center">
      <div className="container">
        <div className="text-center font-bold text-3xl text-sky-500 py-6">Administrative page</div>
        <div className="flow-root mb-3">
          <div className="float-left">
            Logged in as
            <b className="text-sky-500">
              {` ${user?.profile.name ?? 'UNKNOWN'}`}
            </b>
          </div>
          <div className="float-right">
            <Button theme="primary" onClick={logout}>
              Logout
            </Button>
          </div>
        </div>

        <hr />

        {!!stats && (
          <>
            <DepartmentFilters
              stats={stats}
              selectedDepartment={selectedDepartment}
              setSelectedDepartment={setSelectedDepartment}
            />
            <hr />
          </>
        )}

        <div className="overflow-y-scroll">
          {filteredUsers?.map((userData: PayloadType) => (
            // eslint-disable-next-line
            <PersonCard key={`atob(${userData.firstName}_${userData.lastName}_${userData.email})`} userData={userData} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoggedInStats;
