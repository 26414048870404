import { AxiosPromise } from 'axios';
import { httpService } from 'services';
import { PayloadType } from 'types/login-admin';
import { PayloadType as UserDataType } from 'types/register-form';

export const loginAdmin = (data: PayloadType): AxiosPromise<any> => httpService.post(
  '/admin/login',
  data,
  {
    withCredentials: true,
  },
);

export const getDump = (token: string): AxiosPromise<UserDataType> => httpService.get(
  '/admin/dump',
  {
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
);
