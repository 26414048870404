import React from 'react';
import cx from 'classnames';
import BackgroundLeft from 'assets/svg/background_left.svg';
import BackgroundRight from 'assets/svg/background_right.svg';
import useWindowWidth from 'hooks/useWIndowWidth';

import styles from './FormContainer.module.css';

interface Props {
  title: string,
  children: React.ReactNode,
}

const FormContainer:React.FC<Props> = ({ children, title }) => {
  const windowWidth = useWindowWidth();

  return (
    <div className={styles.container}>
      {windowWidth > 960 && (
        <div className={styles.imageContainer}>
          <img className={styles.center} src={BackgroundLeft} alt="bg-left" />
        </div>
      )}
      <div className={cx(styles.center, 'flex-1')}>
        <div className={styles.title}>{title}</div>
        {children}
      </div>
      {windowWidth > 960 && (
        <div className={styles.imageContainer}>
          <img className={styles.center} src={BackgroundRight} alt="bg-right" />
        </div>
      )}
    </div>
  );
};

export default FormContainer;
