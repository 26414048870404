import React, { useEffect } from 'react';
import FormContainer from 'components/formContainer';
import { useNavigate } from 'react-router-dom';
import UserHistoryForm from 'components/userHistoryForm';
import Loading from 'components/loading/Loading';
import useStore from 'store';

const HistoryPage: React.FC = () => {
  const navigate = useNavigate();
  const stepper = useStore((state) => state.stepper);

  useEffect(() => {
    if (stepper !== 1) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <div className="w-screen h-screen">
      <FormContainer title="Inscrieri">
        <div className="overflow-y-scroll">
          {stepper === 1 ? <UserHistoryForm /> : <Loading containerClassName="my-3" />}
        </div>
      </FormContainer>
    </div>
  );
};

export default HistoryPage;
