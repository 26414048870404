import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';
import React from 'react';
import { Button } from 'components/buttons';

import styles from './FormContainer.module.css';

type NavigationButtonsProps = {
  isPrevButton?: boolean;
  isNextButton?: boolean;
  onClickNext?: () => void;
  onClickPrev?: () => void;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  isPrevButton, isNextButton, onClickNext, onClickPrev,
}) => (
  <div className={styles.buttonContainer}>
    {isPrevButton && (
      <Button theme="default" onClick={onClickPrev}>
        <ArrowLeftIcon className={styles.iconButton} />
      </Button>
    )}
    {isNextButton && (
      <Button type="submit" theme="primary" onClick={onClickNext}>
        <ArrowRightIcon className={styles.iconButton} />
      </Button>
    )}
  </div>
);

export default NavigationButtons;
