import React from 'react';

type ResponseItemType = {
  title: string;
  response: string;
}

const ResponseItem: React.FC<ResponseItemType> = ({ title, response }) => (
  <li className="text-lg text-gray-600">
    <p>{title}</p>
    <p className="text-sky-600">{response}</p>
    <br />
  </li>
);

export default ResponseItem;
